
 import React from "react";

 import Table from "@material-ui/core/Table";
 import TableBody from "@material-ui/core/TableBody";
 import TableCell from "@material-ui/core/TableCell";
 import TableContainer from "@material-ui/core/TableContainer";
 import TableHead from "@material-ui/core/TableHead";
 import TableRow from "@material-ui/core/TableRow";
 import Paper from "@material-ui/core/Paper";
 
 class ReportCardTable extends React.Component {
   constructor() {
     super();
     // this.getReportCard()
     // this.getComments()
   }
   
   render() {
     const classes = {
       table: {
         width: "3in",
       },
     };

     return (

        <TableContainer
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          overflow: 'hidden'
        }}
        component={Paper}
      >
        <Table style={{ width: "3.8in" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  paddingTop: "16px",
                  paddingBottom: "16px",
                  paddingRight: "8px",
                  paddingLeft: "8px",
                }}
              >
                {this.props.title}
              </TableCell>
              {this.props.columns &&
                this.props.columns.map((column, index) => (
                  <TableCell
                    style={{ padding: "8px" }}
                    align="center"
                    key={index}
                  >
                    {column}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.rows &&
              this.props.rows.map((row) => (
                <TableRow key={row[0]}>
                  <TableCell
                    style={{
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingRight: "8px",
                      paddingLeft: "8px",
                    }}
                    component="th"
                    scope="row"
                  >
                    {row[0]}
                  </TableCell>
                  {row.map((r, i) => {
                    if (i != 0) {
                      return (
                        <TableCell
                          style={{
                            paddingTop: "16px",
                            paddingBottom: "16px",
                            paddingRight: "8px",
                            paddingLeft: "8px",
                          }}
                          key={i}
                          align="center"
                        >
                          {r}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
     );
   }
 }
 export default ReportCardTable;
 