import React, {Component} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form"
// import {createChannel} from "../Utils";

class Confirm extends Component {
    constructor(props) {
        super(props);
        this.state={
            isOpen:false,
        }
    }

open=()=>{
    this.setState({
        isOpen:true
    })
}

componentWillReceiveProps(nextProps) {
    this.setState({
        isOpen: nextProps.isOpen
    });
}

    render() {
        return (
            <Modal show={this.state.isOpen}   >
                <Modal.Header closeButton={false}>
                    <Modal.Title>{this.props.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                   {this.props.children}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{this.props.closeFunction();
                        this.setState({ className:"",points:""})

                    }}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={()=>{
                            this.props.saveFunction();
                        }}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

Confirm.propTypes = {};

export default Confirm;
