import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Table from "./Table";
import styles from "./marquee.css";

import baseUrl, {
  isOhrHachaim,
  getStudentData
} from "../Utils";

import { Helmet } from "react-helmet";

class Marquee extends React.Component {
  constructor(props) {
    super(props);
    // this.getStudents()
    this.state = {
      studentData1: [],
      studentData2: [],
      newSubcategoryOpen: false,

      studentColumns: [
        {
          dataField: "bank_account_number",
          text: "Bank account number",
          sort: true,
          editable: false,
          formatter: (cell, row) => {
              return (
                  <strong 
                  style={{
                      fontSize:"1.7em"
                    }}
                >{cell} </strong>
              );
            }
        },
        {
          dataField: "points",
          text: isOhrHachaim ? "YEAR TO DATE" : "Points",
          sort: true,
          row: 1,
          editable: false,
          formatter: (cell, row) => {
            if (row.tests != "" || row.retake != "" || row.marks < -4) {
              return (
                <span
                  className={"strikeout"}
                  style={{
                    width: "100%",
                    height: "100%",
                    background: "black",
                    padding: "0px",
                  }}
                >
                  <strong style={{ color: "white" }}>{cell} </strong>
                </span>
              );
            }
            return <span style={{ fontSize: "1.7em" }}>{cell}</span>;
          },
        },
        {
          dataField: "marks",
          text: "Marks",
          sort: true,
          editable: false,
        },
        {
          dataField: "tests",
          text: "Missing Tests",
          sort: true,
          editable: false,
        },
        {
          dataField: "retake",
          text: "Missing Retakes",
          sort: true,
          editable: false,
        },
        {
          dataField: "percentOfModel",
          text: "%",
        }
      ],
    };


    this.setClass();
    //update this data once a day
    setInterval(() => {
        this.setClass();
    }, (60000 * 60));
    
  }

  createEmptyRow () {
      return {
          bank_account_number: "",
          class: "",
          first_name: "",
          grade: "",
          id: Math.random(1,10),
          last_name: "",
          marks: "",
          missingTests: [],
          points: "",
          retake: "",
          retakes: [],
          percentOfModel: "",
          tests: ""};
  }

  async setClass() {
      let res = await getStudentData();

        res.sort((a, b) => (a.bank_account_number > b.bank_account_number ? 1 : -1));

        const arr1 = res.slice(0, Math.floor(res.length/2));
        const arr2 = res.slice(Math.floor(res.length/2));

        //hack to force empty rows
        arr1.unshift(this.createEmptyRow());
        arr1.unshift(this.createEmptyRow());
        arr1.unshift(this.createEmptyRow());
        arr1.unshift(this.createEmptyRow());
        arr1.unshift(this.createEmptyRow());

        arr2.unshift(this.createEmptyRow());
        arr2.unshift(this.createEmptyRow());
        arr2.unshift(this.createEmptyRow());
        arr2.unshift(this.createEmptyRow());
        arr2.unshift(this.createEmptyRow());
        
        this.setState({ studentData1:  arr1 });
        this.setState({ studentData2:  arr2 });
  }

  render() {
    return (
      <div>

        <div style={{ padding: "15px" }}>
          <div
            className="row"
            style={{ textAlign: "center", paddingBottom: "15px" }}
          >
            <div
              className="col-sm-12 col-lg-6"
              style={{ paddingTop: "10px" }}
            ></div>

            <div
              className="col-sm-12 col-lg-6"
              style={{ paddingRight: "30px", paddingBottom: "10px" }}
            >
              {/* <Button style={{float:"right"}} onClick={()=>{this.submit()}}>
                                    Save
                                </Button>*/}
            </div>
          </div>

          {this.state.studentData1 != undefined ? (
            <div className="marquee">
            <div className="inner">
            <Table
              defaultSorted={[
                { dataField: "bank_account_number", order: "asc" },
              ]}
              pagination={false}
              switch={false}
              search={false}
              delete={false}
              editable={false}
              editVerify={false}
              selectable={false}
              columns={this.state.studentColumns}
              data={this.state.studentData1}
              outerStyle={{float:"left", width:"48%"}}
            />
            <Table
              defaultSorted={[
                { dataField: "bank_account_number", order: "asc" },
              ]}
              pagination={false}
              switch={false}
              search={false}
              delete={false}
              editable={false}
              editVerify={false}
              selectable={false}
              columns={this.state.studentColumns}
              data={this.state.studentData2}
              outerStyle={{float:"right", width:"48%"}}
            />
            </div>
            </div>
          ) : (
            ""
          )}
      </div>
      </div>
    );
  }
}
export default Marquee;
