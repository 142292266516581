import React from "react";

import Table from "./Table";

import { FaChevronDown, FaCheck, FaTimes } from "react-icons/fa";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../GlobalVariables";
import baseUrl, { authentication } from "../Utils";
import Dialog from "./dialogs/dialog";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";


import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const subjectColumns = [
  {
    headerStyle: (colum, colIndex) => {
      return { width: "70%", textAlign: "center" };
    },
    dataField: "name",
    text: "Subjects",
    sort: true,
  },
  {
    dataField: "has_points",
    text: "Gets Test Points",
    sort: true,
    formatter: (cell, row, rowIndex) => {
      if (cell == true) {
        return (
          <>
            <div style={{ textAlign: "center" }}>
              <FaCheck color="green" size="1.5em" />
            </div>
            <Link target="_blank" to={`/advanced-test-settings/${row.id}`}>
              Go to Advanced Test Settings
            </Link>
          </>
        );
      }
    },
    // formatter:(cell,row,rowIndex)=>{return<div onClick={(e)=>{this.info.map((item)=>{if(item.id==row.id){item.noWork=e.target.checked}});this.setState({studentData:this.info})}}><input value={cell} defaultChecked={cell} type="checkbox"/></div>},
  },
];
const teacherColumns = [
  {
    headerStyle: (colum, colIndex) => {
      return { width: "90%", textAlign: "center" };
    },
    dataField: "name",
    text: "Teachers",
    sort: true,
  },
];
const canteenColumns = [
  {
    headerStyle: (colum, colIndex) => {
      return { width: "70%", textAlign: "center" };
    },
    dataField: "name",
    text: "Canteen Items",
    sort: true,
  },
  {
    dataField: "points",
    text: "Points",
    sort: true,
  },
];
const specialtyColumns = [
  {
    headerStyle: (colum, colIndex) => {
      return { width: "70%", textAlign: "center" };
    },
    dataField: "name",
    text: "Specialty Items",
    sort: true,
  },
  {
    dataField: "points",
    text: "Points",
    sort: true,
  },
];
const behaviorTypeColumns = [
  {
    headerStyle: (colum, colIndex) => {
      return { width: "90%", textAlign: "center" };
    },
    dataField: "name",
    text: "Report Card Behavior Types",
    sort: true,
  },
];

const categoryColumns = [
  {
    headerStyle: (colum, colIndex) => {
      return { width: "90%", textAlign: "center" };
    },
    dataField: "name",
    text: "Categories",
    sort: true,
  },
];

class Settings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // possibleGrades: [],
      subjects: [],
      teachers: [],
      canteenItems: [],
      specialtyItems: [],
      behaviorTypes: [],
      categories: [],
      canteenItemOpen: false,
      specialtyItemOpen: false,
      newSubjectOpen: false,
      newTeacherOpen: false,
      behaviorTypeOpen: false,
      categoryOpen: false,
    };
    this.getSubjects();
    this.getTeachers();
    //this.getPossibleGrades()
    this.getCanteen();
    this.getSpecialty();
    this.getBehaviorTypes();
    this.getCategories();
  }

  getSubjects() {
    fetch(baseUrl + "/subjects", {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.forEach((a) => {
          a.id = parseInt(a.id);
        });
        this.setState({ subjects: res });
      });
  }

  getTeachers() {
    fetch(baseUrl + "/teachers", {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.forEach((a) => {
          a.id = parseInt(a.id);
        });
        this.setState({ teachers: res });
      });
  }

  getCanteen() {
    fetch(baseUrl + "/canteen-items", {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.forEach((a) => {
          a.id = parseInt(a.id);
        });
        this.setState({ canteenItems: res });
      });
  }

  getSpecialty() {
    fetch(baseUrl + "/specialty-items", {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.forEach((a) => {
          a.id = parseInt(a.id);
        });
        this.setState({ specialtyItems: res });
      });
  }
  getBehaviorTypes() {
    fetch(baseUrl + "/behavior-type", {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.forEach((a) => {
          a.id = parseInt(a.id);
        });
        this.setState({ behaviorTypes: res });
      });
  }

  getCategories() {
    fetch(`${baseUrl}/categories`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        console.log(
          "🚀 ~ file: Settings.js ~ line 228 ~ Settings ~ .then ~ res",
          res
        );
        this.setState({ categories: res });
      });
  }

  saveNewTeacher = (teacher) => {
    fetch(`${baseUrl}/new-teacher/${teacher}`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => response.text())
      .then((text) => {
        // alert(text)
        if (text == "successful") {
          this.setState({ newTeacherOpen: false });
          this.getTeachers();
        }
      });
  };
  saveNewCanteenItem = (name, points) => {
    fetch(`${baseUrl}/canteen-items/${name}/${points}`, {
      headers: {
        Authorization: authentication,
      },
    }).then(() => {
      // alert(text)

      this.setState({ canteenItemOpen: false });
      this.getCanteen();
    });
  };
  saveNewSpecialtyItem = (name, points) => {
    fetch(`${baseUrl}/specialty-items/${name}/${points}`, {
      headers: {
        Authorization: authentication,
      },
    }).then(() => {
      this.setState({ specialtyItemOpen: false });
      this.getSpecialty();
    });
  };
  saveNewBehaviorType = (name) => {
    fetch(`${baseUrl}/new-behavior-type/${name}`, {
      headers: {
        Authorization: authentication,
      },
    }).then(() => {
      this.setState({ behaviorTypeOpen: false });
      this.getBehaviorTypes();
    });
  };
  saveNewCategory = (name) => {
    fetch(`${baseUrl}/new-category/${name}`, {
      headers: {
        Authorization: authentication,
      },
    }).then(() => {
      this.setState({ categoryOpen: false });
      this.getCategories();
    });
  };

  saveNewSubject = (subject, hasPoints) => {
    fetch(`${baseUrl}/new-subject/${subject}/${hasPoints}`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => response.text())
      .then((text) => {
        // alert(text)
        if (text == "successful") {
          this.setState({ newSubjectOpen: false });
          this.getSubjects();
        }
      });
  };

  deleteSubject(subjects) {
    if (subjects.length > 0) {
      subjects = { subjects: subjects };
      subjects = JSON.stringify(subjects);
      fetch(`${baseUrl}/delete-subjects/${subjects}`, {
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => response.text())
        .then((text) => {
          //  alert(text)
          if (text == "successful") {
            this.getSubjects();
          }
        });
    }
  }

  deleteTeacher(teachers) {
    if (teachers.length > 0) {
      teachers = { teachers: teachers };
      teachers = JSON.stringify(teachers);
      fetch(`${baseUrl}/delete-teachers/${teachers}`, {
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => response.text())
        .then((text) => {
          //  alert(text)
          if (text == "successful") {
            console.log("Deleted");
            this.getTeachers();
          }
        });
    }
  }

  deleteCanteen(items) {
    if (items.length > 0) {
      items = { items: items };
      items = JSON.stringify(items);
      fetch(`${baseUrl}/delete-canteen/${items}`, {
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => response.text())
        .then((text) => {
          if (text == "successful") {
            this.getSpecialty();
            this.getCanteen();
          }
        });
    }
  }

  deleteBehaviorType(items) {
    if (items.length > 0) {
      items = { items: items };
      items = JSON.stringify(items);
      fetch(`${baseUrl}/delete-behavior-type/${items}`, {
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => response.text())
        .then((text) => {
          if (text == "successful") {
            this.getBehaviorTypes();
          }
        });
    }
  }
  deleteCategory(items) {
    if (items.length > 0) {
      items = { items: items };
      items = JSON.stringify(items);
      fetch(`${baseUrl}/delete-category/${items}`, {
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => response.text())
        .then((text) => {
          if (text == "successful") {
            this.getCategories();
          }
        });
    }
  }

  graduate() {
    fetch(`${baseUrl}/graduate`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => response.text())
      .then((text) => {
        alert(text);
      });
  }

  render() {
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href="home">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Settings</Breadcrumb.Item>
        </Breadcrumb>
        <Dialog
          name="Subject"
          isDialogOpen={this.state.newSubjectOpen}
          saveFunction={this.saveNewSubject}
          closeFunction={() => {
            this.setState({ newSubjectOpen: false });
          }}
        />
        <Dialog
          name="Teacher"
          isDialogOpen={this.state.newTeacherOpen}
          saveFunction={this.saveNewTeacher}
          closeFunction={() => {
            this.setState({ newTeacherOpen: false });
          }}
        />
        <Dialog
          name="Behavior Type"
          isDialogOpen={this.state.behaviorTypeOpen}
          saveFunction={this.saveNewBehaviorType}
          closeFunction={() => {
            this.setState({ behaviorTypeOpen: false });
          }}
        />
        <Dialog
          name="Category"
          isDialogOpen={this.state.categoryOpen}
          saveFunction={this.saveNewCategory}
          closeFunction={() => {
            this.setState({ categoryOpen: false });
          }}
        />

        <Dialog
          reward={true}
          name="Canteen Item"
          isDialogOpen={this.state.canteenItemOpen}
          saveFunction={this.saveNewCanteenItem}
          closeFunction={() => {
            this.setState({ canteenItemOpen: false });
          }}
        />
        <Dialog
          reward={true}
          name="Specialty Item"
          isDialogOpen={this.state.specialtyItemOpen}
          saveFunction={this.saveNewSpecialtyItem}
          closeFunction={() => {
            this.setState({ specialtyItemOpen: false });
          }}
        />

        <div style={{ padding: "25px" }}>
          <div
            className="row"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              textAlign: "right",
            }}
          >
            <Button
              style={{ float: "right" }}
              onClick={() => {
                if (
                  prompt("This action is restricted. Please enter password") ==
                  "1298"
                ) {
                  this.graduate();
                }
              }}
            >
              Graduate system to new year
            </Button>
          </div>
          <div className="row">
            <div className="col-sm-6" style={{}}>
              <Table
                ref={(n) => (this.subjectTableRef = n)}
                addFunction={() => {
                  this.setState({ newSubjectOpen: true });
                }}
                add={true}
                pagination={false}
                search={false}
                delete={true}
                deleteClick={() => {
                  this.deleteSubject(this.subjectTableRef.state.selected);
                }}
                switch={false}
                columns={subjectColumns}
                data={this.state.subjects}
              />
            </div>
            <div className="col-sm-6" style={{}}>
              <Table
                ref={(n) => (this.teacherTableRef = n)}
                addFunction={() => {
                  this.setState({ newTeacherOpen: true });
                }}
                add={true}
                pagination={false}
                search={false}
                delete={true}
                deleteClick={() => {
                  this.deleteTeacher(this.teacherTableRef.state.selected);
                }}
                switch={false}
                columns={teacherColumns}
                data={this.state.teachers}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6" style={{}}>
              <Table
                ref={(n) => (this.canteenTableRef = n)}
                addFunction={() => {
                  this.setState({ canteenItemOpen: true });
                }}
                add={true}
                pagination={false}
                search={false}
                delete={true}
                deleteClick={() => {
                  this.deleteCanteen(this.canteenTableRef.state.selected);
                }}
                switch={false}
                columns={canteenColumns}
                data={this.state.canteenItems}
              />
            </div>
            <div className="col-sm-6" style={{}}>
              <Table
                ref={(n) => (this.specialtyTableRef = n)}
                addFunction={() => {
                  this.setState({ specialtyItemOpen: true });
                }}
                add={true}
                pagination={false}
                search={false}
                delete={true}
                deleteClick={() => {
                  this.deleteCanteen(this.specialtyTableRef.state.selected);
                }}
                switch={false}
                columns={specialtyColumns}
                data={this.state.specialtyItems}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6" style={{}}>
              <Table
                ref={(n) => (this.behaviorTypeTableRef = n)}
                addFunction={() => {
                  this.setState({ behaviorTypeOpen: true });
                }}
                add={true}
                pagination={false}
                search={false}
                delete={true}
                deleteClick={() => {
                  this.deleteBehaviorType(
                    this.behaviorTypeTableRef.state.selected
                  );
                }}
                switch={false}
                columns={behaviorTypeColumns}
                data={this.state.behaviorTypes}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6" style={{}}>
              <Table
                ref={(n) => (this.categoryTableRef = n)}
                addFunction={() => {
                  this.setState({ categoryOpen: true });
                }}
                add={true}
                pagination={false}
                search={false}
                delete={true}
                deleteClick={() => {
                  this.deleteCategory(this.categoryTableRef.state.selected);
                }}
                switch={false}
                columns={categoryColumns}
                data={this.state.categories}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Settings;
/*

getPossibleGrades() {
    fetch(baseUrl + "/possible-grades").then(response => {
        return response.json();
    }).then(res => {
        let grd = [];
        res.forEach((a) => {
            a.id = parseInt(a.id)
            grd.push({value: a.id, label: a.name})
        })
        this.setState({possibleGrades: grd})
    })

}*/
