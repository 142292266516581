import React from "react";
import Button from "react-bootstrap/Button";
import Table from "./Table";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../GlobalVariables";
import { Type } from "react-bootstrap-table2-editor";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Form } from "bootstrap-4-react";
import Select from "react-select";
import baseUrl, { authentication, getGrades, getTeachers } from "../Utils";

class NewReportCardBehavior extends React.Component {
  info = [];
  reset = () => {
    this.setState({
      studentData: [],
      defaultGradeSelectVal: "default",
      defaultClassSelectVal: "default",
      defaultTeacherSelectVal: "default",

      teacherId: "",
      studentPoints: new Map(),

      grade: "",
      behaviorTypesValues: []

      // classes:[],
    });
  };
  constructor(props) {
    super(props);
    this.state = {
      classId: "",
      date: "",
      studentData: [],
      defaultGradeSelectVal: "default",
      defaultClassSelectVal: "default",

      grades: [],
      grade: "",
      term: "",

      classes: [],
      defaultTermSelectVal: "default",
      studentColumns: [],
    };
    //this.getClasses()
    getGrades(this);
    getTeachers(this);
    this.getBehaviorTypesValues();
    //   this.getBehaviorTypes()
  }

  getBehaviorTypesValues() {
    fetch(`${baseUrl}/settings-by-type/report_card_behavior_grade`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.length) {
          let values = [];
          res.forEach(r => {
            values.push ({
              value: r.value,
              label: r.value
            })
          })
          this.setState({behaviorTypesValues: values});
        } else {
          this.setState({behaviorTypesValues: [{
            value: "S",
            label: "S",
          },
          {
            value: "E",
            label: "E",
          },
          {
            value: "S-",
            label: "S-",
          },
          {
            value: "NI",
            label: "NI",
          }] 
          });
        }
      });
  }

  getClasses(grade) {
    fetch(`${baseUrl}/grade/${grade}`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.forEach((a) => {
          a.id = parseInt(a.id);
        });
        this.setState({ classes: res });
      });
  }

  getBehaviorTypes(students) {
    fetch(`${baseUrl}/behavior-type`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        fetch(
          `${baseUrl}/reportcard-behavior/${this.state.classId}/${this.state.term}`,
          {
            headers: {
              Authorization: authentication,
            },
          }
        )
          .then((response2) => {
            return response2.json();
          })
          .then((res2) => {
            if (res2.length > 0) {
              students = res2;
            }
            this.state.studentColumns = [];
            this.state.studentColumns.push(
              {
                dataField: "first_name",
                text: "First Name",
                sort: true,
                editable: false,
              },
              {
                dataField: "last_name",
                text: "Last Name",
                sort: true,
                editable: false,
              }
            );

            res.forEach((a) => {
              // a.id=parseInt(a.id)
              students.map((kid) => {
                if (!kid[a.id]) {
                  kid[a.id] = "S";
                }
              });
              // })
              //console.log(this.state.studentData)

              //let n=`behavior${a.id}`

              this.state.studentColumns.push({
                dataField: a.id,
                text: a.name,
                // editor: {
                //     type: Type.TEXT
                // }
                editor: {
                  type: Type.SELECT,
                  getOptions: (setOptions, { row, column }) => {
                    return this.state.behaviorTypesValues;
                  },
                },
              });
            });
            //this.setState({studentData:students})

            this.setState({
              behaviortypes: res,
              studentColumns: this.state.studentColumns,
              studentData: students,
            });
          });
      });
  }

  setClass() {
    if (this.state.classId != "" && this.state.term != "") {
      let formData = new FormData();
      formData.append("action", "get class");
      formData.append("id", this.state.classId);
      var url = "http://ohrhatorah.appsenses.com/students.php";
      url = `${baseUrl}/really-bare-class/${this.state.classId}`;
      fetch(url, {
        method: "GET",
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          res.map((item) => {
            item.id = parseInt(item.id);

            this.info.push(item);
          });
          this.getBehaviorTypes(res);
          //this.getOldComments(res)
        });
    }
  }
  getOldComments(students) {
    if (this.state.term != "" && this.state.classId != "") {
      let url = `${baseUrl}/reportcard-comments/${this.state.classId}/${this.state.term}`;
      fetch(url, {
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          res.forEach((a) => {
            students.forEach((b) => {
              if (a.student_id == b.id) {
                b.comment = a.comment;
              }
            });

            a.id = parseInt(a.id);
          });
          this.setState({ studentData: students });
        });
    }
  }

  validate = () => {
    return true;
  };
  save = () => {
    if (this.validate()) {
      let obj = {};

      // let date = this.state.formattedDate;
      let students = {
        students: this.state.studentData,
        term: this.state.term,
        teacher: this.state.teacherId,
        class: this.state.classId,
      };
      students = JSON.stringify(students);

      let formData = new FormData();
      formData.append("data", students);

      console.log(students);

      ///${this.state.teacherId}/${date}/${this.state.classId}
      fetch(`${baseUrl}/new-reportcard-behavior`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => response.text())

        .then((text) => {
          students = "";

          if (text == "successful") {
            this.reset();
          } else {
            alert(text);
          }
        });
    }
  };

  render() {
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>

          <Breadcrumb.Item active>Report Cards</Breadcrumb.Item>
          <Breadcrumb.Item active>Behavior</Breadcrumb.Item>
        </Breadcrumb>
        <div>
          <div style={{ paddingRight: "30px", paddingBottom: "10px" }}>
            <Button
              style={{ float: "right" }}
              onClick={() => {
                this.save();
              }}
            >
              Save
            </Button>
          </div>
          <div
            className="row"
            style={{
              textAlign: "center",
              padding: "15px",
              paddingLeft: "25px",
            }}
          >
            <div
              className="col-sm-12 col-lg-6"
              style={{
                padding: "15px",
                paddingLeft: "25px",
                textAlign: "left",
              }}
            >
              <Form.CustomSelect
                style={{ maxWidth: "300px" }}
                lg
                mb="3"
                value={this.state.defaultTermSelectVal}
                onChange={(v) => {
                  if (v.target.value != "default") {
                    this.setState(
                      {
                        term: v.target.value,
                        defaultTermSelectVal: v.target.value,
                      },
                      this.setClass
                    );
                  }
                }}
              >
                <option value="1">Term 1</option>
                <option value="2">Term 2</option>
                <option value="3">Term 3</option>
                {/*<option value="4">Term 4</option>*/}
                {this.state.defaultTermSelectVal == "default" && (
                  <option value="default">Choose Term</option>
                )}
              </Form.CustomSelect>
            </div>

            <div className="col-sm-12 col-lg-6" style={{ paddingTop: "10px" }}>
              <Form.CustomSelect
                lg
                mb="3"
                value={this.state.defaultGradeSelectVal}
                onChange={(v) => {
                  if (v.target.value != "default") {
                    this.setState({
                      grade: v.target.value,
                      defaultGradeSelectVal: v.target.value,
                      defaultClassSelectVal: "default",
                    });
                    this.getClasses(v.target.value);
                    console.log(this.state.grade);
                  }
                }}
              >
                {this.state.grades.map((x, y) => (
                  <option value={x.id} key={y}>
                    {x.name}
                  </option>
                ))}
                {this.state.defaultGradeSelectVal == "default" && (
                  <option value="default">Choose Grade</option>
                )}
              </Form.CustomSelect>
            </div>
          </div>

          <div
            className="row"
            style={{
              textAlign: "center",
              padding: "15px",
              paddingLeft: "25px",
            }}
          >
            <div className="col-sm-6" style={{ paddingTop: "10px" }}>
              {this.state.grade != "" && (
                <Form.CustomSelect
                  lg
                  mb="3"
                  value={this.state.defaultClassSelectVal}
                  onChange={(v) => {
                    if (v.target.value != "default") {
                      this.setState(
                        {
                          classId: v.target.value,
                          defaultClassSelectVal: v.target.value,
                        },
                        this.setClass
                      );
                    }
                  }}
                >
                  {this.state.classes.map((x, y) => (
                    <option value={x.id} key={y}>
                      {x.class}
                    </option>
                  ))}
                  {this.state.defaultClassSelectVal == "default" && (
                    <option value="default">Choose Class</option>
                  )}
                </Form.CustomSelect>
              )}
            </div>
          </div>

          {this.state.studentData.length > 0 && (
            <div style={{ padding: "15px" }}>
              <Table
                pagination={false}
                selectable={false}
                delete={false}
                switch={false}
                editable={true}
                editFunc={(oldValue, newValue, row, column) => {
                  if (column.text == "Comment") {
                    this.info.map((item, index) => {
                      if (item.id == row.id) {
                        item.comment = newValue;
                      }
                    });
                  }
                }}
                columns={this.state.studentColumns}
                data={this.state.studentData}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default NewReportCardBehavior;
